exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-jobs-js": () => import("./../../../src/pages/careers/jobs.js" /* webpackChunkName: "component---src-pages-careers-jobs-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-customer-information-js": () => import("./../../../src/pages/customer-information.js" /* webpackChunkName: "component---src-pages-customer-information-js" */),
  "component---src-pages-developers-js": () => import("./../../../src/pages/developers.js" /* webpackChunkName: "component---src-pages-developers-js" */),
  "component---src-pages-ecosystem-partner-newsletter-js": () => import("./../../../src/pages/ecosystem-partner-newsletter.js" /* webpackChunkName: "component---src-pages-ecosystem-partner-newsletter-js" */),
  "component---src-pages-ecosystem-partner-newsletter-success-js": () => import("./../../../src/pages/ecosystem-partner-newsletter-success.js" /* webpackChunkName: "component---src-pages-ecosystem-partner-newsletter-success-js" */),
  "component---src-pages-ecosystem-partners-js": () => import("./../../../src/pages/ecosystem-partners.js" /* webpackChunkName: "component---src-pages-ecosystem-partners-js" */),
  "component---src-pages-es-js": () => import("./../../../src/pages/es.js" /* webpackChunkName: "component---src-pages-es-js" */),
  "component---src-pages-es-privacy-policy-js": () => import("./../../../src/pages/es/privacy-policy.js" /* webpackChunkName: "component---src-pages-es-privacy-policy-js" */),
  "component---src-pages-exchange-rates-mastercard-js": () => import("./../../../src/pages/exchange-rates/mastercard.js" /* webpackChunkName: "component---src-pages-exchange-rates-mastercard-js" */),
  "component---src-pages-fr-js": () => import("./../../../src/pages/fr.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-futura-newsletter-js": () => import("./../../../src/pages/futura-newsletter.js" /* webpackChunkName: "component---src-pages-futura-newsletter-js" */),
  "component---src-pages-futura-newsletter-success-js": () => import("./../../../src/pages/futura-newsletter-success.js" /* webpackChunkName: "component---src-pages-futura-newsletter-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-industries-mobility-js": () => import("./../../../src/pages/industries/mobility.js" /* webpackChunkName: "component---src-pages-industries-mobility-js" */),
  "component---src-pages-industries-tech-and-saas-js": () => import("./../../../src/pages/industries/tech-and-saas.js" /* webpackChunkName: "component---src-pages-industries-tech-and-saas-js" */),
  "component---src-pages-industries-travel-js": () => import("./../../../src/pages/industries/travel.js" /* webpackChunkName: "component---src-pages-industries-travel-js" */),
  "component---src-pages-industries-wealth-js": () => import("./../../../src/pages/industries/wealth.js" /* webpackChunkName: "component---src-pages-industries-wealth-js" */),
  "component---src-pages-investors-download-js": () => import("./../../../src/pages/investors/download.js" /* webpackChunkName: "component---src-pages-investors-download-js" */),
  "component---src-pages-investors-js": () => import("./../../../src/pages/investors.js" /* webpackChunkName: "component---src-pages-investors-js" */),
  "component---src-pages-it-js": () => import("./../../../src/pages/it.js" /* webpackChunkName: "component---src-pages-it-js" */),
  "component---src-pages-knowledge-js": () => import("./../../../src/pages/knowledge.js" /* webpackChunkName: "component---src-pages-knowledge-js" */),
  "component---src-pages-license-js": () => import("./../../../src/pages/license.js" /* webpackChunkName: "component---src-pages-license-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-newsletter-success-js": () => import("./../../../src/pages/newsletter-success.js" /* webpackChunkName: "component---src-pages-newsletter-success-js" */),
  "component---src-pages-partner-information-js": () => import("./../../../src/pages/partner-information.js" /* webpackChunkName: "component---src-pages-partner-information-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-publications-bnpl-guide-js": () => import("./../../../src/pages/publications/bnpl-guide.js" /* webpackChunkName: "component---src-pages-publications-bnpl-guide-js" */),
  "component---src-pages-publications-cards-guide-js": () => import("./../../../src/pages/publications/cards-guide.js" /* webpackChunkName: "component---src-pages-publications-cards-guide-js" */),
  "component---src-pages-publications-digital-banking-guide-js": () => import("./../../../src/pages/publications/digital-banking-guide.js" /* webpackChunkName: "component---src-pages-publications-digital-banking-guide-js" */),
  "component---src-pages-publications-embedded-finance-study-js": () => import("./../../../src/pages/publications/embedded-finance-study.js" /* webpackChunkName: "component---src-pages-publications-embedded-finance-study-js" */),
  "component---src-pages-publications-futura-whitepaper-js": () => import("./../../../src/pages/publications/futura-whitepaper.js" /* webpackChunkName: "component---src-pages-publications-futura-whitepaper-js" */),
  "component---src-pages-publications-future-embedded-finance-whitepaper-js": () => import("./../../../src/pages/publications/future-embedded-finance-whitepaper.js" /* webpackChunkName: "component---src-pages-publications-future-embedded-finance-whitepaper-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-publications-kyc-guide-js": () => import("./../../../src/pages/publications/kyc-guide.js" /* webpackChunkName: "component---src-pages-publications-kyc-guide-js" */),
  "component---src-pages-publications-mobility-whitepaper-js": () => import("./../../../src/pages/publications/mobility-whitepaper.js" /* webpackChunkName: "component---src-pages-publications-mobility-whitepaper-js" */),
  "component---src-pages-publications-payment-flows-guide-js": () => import("./../../../src/pages/publications/payment-flows-guide.js" /* webpackChunkName: "component---src-pages-publications-payment-flows-guide-js" */),
  "component---src-pages-publications-roland-berger-whitepaper-js": () => import("./../../../src/pages/publications/roland-berger-whitepaper.js" /* webpackChunkName: "component---src-pages-publications-roland-berger-whitepaper-js" */),
  "component---src-pages-publications-travel-whitepaper-js": () => import("./../../../src/pages/publications/travel-whitepaper.js" /* webpackChunkName: "component---src-pages-publications-travel-whitepaper-js" */),
  "component---src-pages-publications-wealth-whitepaper-js": () => import("./../../../src/pages/publications/wealth-whitepaper.js" /* webpackChunkName: "component---src-pages-publications-wealth-whitepaper-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-services-accounts-js": () => import("./../../../src/pages/services/accounts.js" /* webpackChunkName: "component---src-pages-services-accounts-js" */),
  "component---src-pages-services-bankident-js": () => import("./../../../src/pages/services/bankident.js" /* webpackChunkName: "component---src-pages-services-bankident-js" */),
  "component---src-pages-services-cards-js": () => import("./../../../src/pages/services/cards.js" /* webpackChunkName: "component---src-pages-services-cards-js" */),
  "component---src-pages-services-consumer-lending-js": () => import("./../../../src/pages/services/consumer-lending.js" /* webpackChunkName: "component---src-pages-services-consumer-lending-js" */),
  "component---src-pages-services-digital-banking-js": () => import("./../../../src/pages/services/digital-banking.js" /* webpackChunkName: "component---src-pages-services-digital-banking-js" */),
  "component---src-pages-services-fronting-js": () => import("./../../../src/pages/services/fronting.js" /* webpackChunkName: "component---src-pages-services-fronting-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-kyc-platform-js": () => import("./../../../src/pages/services/kyc-platform.js" /* webpackChunkName: "component---src-pages-services-kyc-platform-js" */),
  "component---src-pages-services-payment-flows-js": () => import("./../../../src/pages/services/payment-flows.js" /* webpackChunkName: "component---src-pages-services-payment-flows-js" */),
  "component---src-pages-services-splitpay-js": () => import("./../../../src/pages/services/splitpay.js" /* webpackChunkName: "component---src-pages-services-splitpay-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-survey-js": () => import("./../../../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */),
  "component---src-pages-survey-success-js": () => import("./../../../src/pages/survey-success.js" /* webpackChunkName: "component---src-pages-survey-success-js" */),
  "component---src-pages-webinars-js": () => import("./../../../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */),
  "component---src-templates-contentful-blog-archive-template-js": () => import("./../../../src/templates/ContentfulBlogArchiveTemplate.js" /* webpackChunkName: "component---src-templates-contentful-blog-archive-template-js" */),
  "component---src-templates-contentful-blog-category-template-js": () => import("./../../../src/templates/ContentfulBlogCategoryTemplate.js" /* webpackChunkName: "component---src-templates-contentful-blog-category-template-js" */),
  "component---src-templates-contentful-blog-post-template-js": () => import("./../../../src/templates/ContentfulBlogPostTemplate.js" /* webpackChunkName: "component---src-templates-contentful-blog-post-template-js" */),
  "component---src-templates-contentful-case-study-template-js": () => import("./../../../src/templates/ContentfulCaseStudyTemplate.js" /* webpackChunkName: "component---src-templates-contentful-case-study-template-js" */),
  "component---src-templates-contentful-customer-information-detail-template-js": () => import("./../../../src/templates/ContentfulCustomerInformationDetailTemplate.js" /* webpackChunkName: "component---src-templates-contentful-customer-information-detail-template-js" */),
  "component---src-templates-contentful-media-item-template-js": () => import("./../../../src/templates/ContentfulMediaItemTemplate.js" /* webpackChunkName: "component---src-templates-contentful-media-item-template-js" */),
  "component---src-templates-contentful-security-article-template-js": () => import("./../../../src/templates/ContentfulSecurityArticleTemplate.js" /* webpackChunkName: "component---src-templates-contentful-security-article-template-js" */),
  "component---src-templates-contentful-support-category-template-js": () => import("./../../../src/templates/ContentfulSupportCategoryTemplate.js" /* webpackChunkName: "component---src-templates-contentful-support-category-template-js" */),
  "component---src-templates-contentful-support-client-template-js": () => import("./../../../src/templates/ContentfulSupportClientTemplate.js" /* webpackChunkName: "component---src-templates-contentful-support-client-template-js" */),
  "component---src-templates-contentful-text-page-template-js": () => import("./../../../src/templates/ContentfulTextPageTemplate.js" /* webpackChunkName: "component---src-templates-contentful-text-page-template-js" */),
  "component---src-templates-podcast-page-template-js": () => import("./../../../src/templates/PodcastPageTemplate.js" /* webpackChunkName: "component---src-templates-podcast-page-template-js" */),
  "component---src-templates-withdrawal-request-crypto-page-template-js": () => import("./../../../src/templates/WithdrawalRequestCryptoPageTemplate.js" /* webpackChunkName: "component---src-templates-withdrawal-request-crypto-page-template-js" */),
  "component---src-templates-withdrawal-request-selection-page-template-js": () => import("./../../../src/templates/WithdrawalRequestSelectionPageTemplate.js" /* webpackChunkName: "component---src-templates-withdrawal-request-selection-page-template-js" */),
  "component---src-templates-withdrawal-request-sepa-page-template-js": () => import("./../../../src/templates/WithdrawalRequestSepaPageTemplate.js" /* webpackChunkName: "component---src-templates-withdrawal-request-sepa-page-template-js" */)
}

